import React, { Component } from 'react';
import { Container, List, Header, Grid, Segment } from "semantic-ui-react";

import { mixpanel } from "../../utils/tracking";

export const columnOne =
    [{
        category: "Knowledge Bank",
        items: [
            {
                'name': 'Feedback tips',
                'url': 'https://docs.google.com/document/d/1umN3smazVlEZ-Y_vI1l6XT4oG6_zzy0flF4OFQyUCxk/edit?usp=sharing'
            },
            {
                'name': '3 question to ask in a 1-on-1',
                'url': 'https://docs.google.com/document/d/1zZIY5aYQqBi0CnhBMYATXrL2SYK6mukY1VKC3HSUwro/edit?usp=drive_open&ouid=109974646452587684508'
            },
            {
                'name': "Why 1-on-1's matter?",
                'url': 'https://docs.google.com/document/d/1-7ysHbePGOSN4F6OmV7fcFhBwDfUMG8UvA9mG7mE4tg/edit'
            },
            {
                'name': 'Listening Drives Motivation',
                'url': 'https://docs.google.com/document/d/1Lj2m2M8TeP1L2hNVn-qCK09n7Y9pUGqi25A3tNovrdk/edit'
            }
        ]
    },
    {
        category: "Tools",
        items: [
            {
                'name': '1-on-1 agenda',
                'url': 'https://docs.google.com/document/d/1Sf1YnZZqZMUgIf51Yvc3eUL4Z4K4-OL5258lnbzfKyo/edit'
            },
            {
                'name': 'Critical conversation framework',
                'url': 'https://drive.google.com/file/d/1_bFgzRLcy2gQ5Wrh0S8sJhy8q80zltze/view?usp=sharing'
            },
            {
                'name': 'Performance review framework',
                'url': 'https://docs.google.com/document/d/1bELXzWnb-te8KiEGkK6CEDvP0C2eMhBxazApTH0K4cQ/edit#'
            },
            {
                'name': 'Rapid feedback model',
                'url': 'https://docs.google.com/document/d/1YSwJLEZ7or0GkBmyrsqbn8wQumKL35wKpHXXQIMU65U/edit#'
            },
            {
                'name': 'Stay interview guide',
                'url': 'https://docs.google.com/document/d/1RbfFXmE5ft_tBezGf_y_r_JOoNolqHe_mdSWmhYUjgM/edit'
            }, {
                'name': 'Self check-in',
                'url': 'https://docs.google.com/document/d/1kikndeUN9opaomVog4HVfb1MRS_tDvX7EKBDps5KjCw/edit?usp=sharing'
            },
            {
                'name': 'Discover Your Listening Blindspot',
                'url': 'https://docs.google.com/forms/d/e/1FAIpQLScBEz4CeTqoFy-tqVKgyzgCr-XerahSSxncOym-OlRzgvN9Qw/viewform?usp=send_form'
            },
            {
                'name': 'Checklist for Listening',
                'url': 'https://docs.google.com/document/d/1ogxbJlhBBGpIbxAgkLpMvfHtkUHQ4wsQwlNnQzWM8L0/edit#'
            }
        ]
    }];

export const columnTwo =
    [{
        category: 'Articles',
        items: [
            {
                'name': 'Critical converstaions',
                'url': 'https://www.forbes.com/sites/forbescoachescouncil/2017/12/01/the-one-leadership-misconception-thats-holding-your-team-back/#5352bd0b2400'
            },
            {
                'name': 'Stay interview',
                'url': 'https://www.forbes.com/sites/forbescoachescouncil/2019/06/10/stop-holding-exit-interviews-hold-stay-interviews-instead/#4602e6f9245c'
            },
            {
                'name': 'Leading a performance review',
                'url': 'https://www.forbes.com/sites/forbescoachescouncil/2018/01/12/how-to-lead-a-productive-performance-review/#29e219c056e5'
            },
            {
                'name': 'How listening drives motivation',
                'url': 'https://openhonestanddirect.com/home/how-to-be-1-better-every-day/'
            },
            {
                'name': 'Stop holding exit interviews, hold stay interviews instead',
                'url': 'https://www.forbes.com/sites/forbescoachescouncil/2019/06/10/stop-holding-exit-interviews-hold-stay-interviews-instead/#6d50ae80245c'
            },
            {
                'name': 'What’s your listening blind spot',
                'url': 'https://www.forbes.com/sites/forbescoachescouncil/2019/03/06/whats-your-listening-blind-spot/#6900c7ca75a9'
            }
            ,
            {
                'name': 'Two ways to stop holding exit interviews',
                'url': 'https://openhonestanddirect.com/home/two-ways-to-stop-hosting-exit-interviews-with-your-millennials/'
            },
            {
                'name': '5 ways to listen better TED talk',
                'url': 'https://www.ted.com/talks/julian_treasure_5_ways_to_listen_better'
            }
        ]
    }
    ];


class Resources extends Component {

    async componentDidMount() {
        mixpanel.track("Viewed Resources Page");
    }

    renderColumn(column) {
        return column.map(resource => {
            return (

                <Segment basic key={resource.category}>
                    <Header as='h2'>{resource.category}</Header>
                    <List relaxed bulleted>
                        {resource.items.map(item =>
                            // eslint-disable-next-line react/jsx-no-target-blank
                            <List.Item key={item.url}>
                                <a
                                    href={item.url}
                                    target="_blank"
                                    onClick={() => mixpanel.track("Clicked Resource", { resource_name: item.name })}
                                >
                                    {item.name}
                                </a>
                            </List.Item>
                        )}
                    </List>
                </Segment>
            )
        });
    }

    render() {
        return (
            <Container text style={{ margin: '4em' }}>
                <Grid stackable columns={2}>
                    <Grid.Column>
                        {this.renderColumn(columnOne)}
                    </Grid.Column>
                    <Grid.Column>
                        {this.renderColumn(columnTwo)}
                    </Grid.Column>
                </Grid>
            </Container>
        )
    }
}


export default Resources;
