import React, { Component } from 'react';
import { Responsive, Menu, Image, Icon, Button, Sidebar } from 'semantic-ui-react';
import { NavLink } from "react-router-dom";
import { MENU_RESPONSIVE_MAX_WIDTH, MENU_RESPONSIVE_MIN_WIDTH } from "utils/constants";
import logoSideText from 'logo_side_text.svg';
import { mixpanel } from "../../utils/tracking";

class MenuBar extends Component {

  renderLeftMenuItems = () => {
    const { hideManageReportsOnDashboard, isCompanyAdmin, isStrideAdmin } = this.props;

    return (<React.Fragment>

      {/* <Menu.Item
        as={NavLink}
        exact
        to="/"
        name='Dashboard'
        icon='chart line'
        onClick={() => this.setState({ showSideBar: false })}
      /> */}

      {/* {!hideManageReportsOnDashboard && (
        <Menu.Item
          as={NavLink}
          exact
          to="/settings"
          name='Manage Reports'
          icon='users'
          onClick={() => this.setState({ showSideBar: false })}
        />)
      } */}

      {/* <Menu.Item
        as={NavLink}
        exact
        to="/resources"
        name='Resources'
        icon='book'
        onClick={() => this.setState({ showSideBar: false })}
      /> */}

      {isCompanyAdmin && (
        <Menu.Item
          as={NavLink}
          exact
          to="/reporting"
          name='Reporting'
          icon='chart area'
          onClick={() => this.setState({ showSideBar: false })}
        />)
      }

      {isStrideAdmin && (
        <Menu.Item
          as={NavLink}
          exact
          to="/reporting_internal"
          name='Reporting Internal'
          icon='chart area'
          onClick={() => this.setState({ showSideBar: false })}
        />)
      }

    </React.Fragment>
    );
  }

  renderRightMenuItems = () => {
    const { logoutDisabled, onClickLogOut } = this.props;

    return (
      <React.Fragment>
        <Menu.Item
          name='Support'
          icon='question circle'
          onClick={
            () => {
              this.setState({ showSideBar: false });
              mixpanel.track("Clicked Support");
              window.open("mailto: support@strideapp.co");
            }
          }
        />
        <Menu.Item >
          <Button
            disabled={logoutDisabled}
            secondary
            onClick={onClickLogOut}
          >Log out</Button>
        </Menu.Item>
      </React.Fragment>
    );
  }

  renderMenuLogo = () => {
    return (
      <Menu.Item
        header
        href={process.env.REACT_APP_MARKETING_URL}
      >
        <Image size='small' src={logoSideText} />
      </Menu.Item>
    );
  }

  constructor(props) {
    super(props);
    this.state = {
      showSideBar: false,
    }
  }

  render() {

    const { children } = this.props

    const { showSideBar } = this.state;

    return (
      <React.Fragment>
        <Responsive maxWidth={MENU_RESPONSIVE_MAX_WIDTH} as={Sidebar.Pushable}>
          <Sidebar
            as={Menu}
            animation='overlay'
            onHide={() => this.setState({ showSideBar: false })}
            vertical
            visible={showSideBar}
            direction="right"
          >
            {this.renderLeftMenuItems()}
            {this.renderRightMenuItems()}
          </Sidebar>
          <Sidebar.Pusher dimmed={showSideBar}>
            <Menu borderless>
              {this.renderMenuLogo()}
              <Menu.Menu position='right'>
                <Menu.Item>
                  <Icon link name='sidebar' onClick={() => this.setState({ showSideBar: true })} />
                </Menu.Item>
              </Menu.Menu>
            </Menu>
            {children}
          </Sidebar.Pusher>
        </Responsive>
        <Responsive minWidth={MENU_RESPONSIVE_MIN_WIDTH}>
          <Menu borderless>
            {this.renderMenuLogo()}
            {this.renderLeftMenuItems()}
            <Menu.Menu position='right'>
              {this.renderRightMenuItems()}
            </Menu.Menu>
          </Menu>
          {children}
        </Responsive>
      </React.Fragment>
    );
  }
}

export default MenuBar;