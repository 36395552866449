import React from 'react'
import { Message, Container } from 'semantic-ui-react'

const AccountInactive = () => (
    <Container style={{ margin: '4em' }}>
        <Message
            negative
            header='Insufficient Permissions'
            content={<p>You do not have sufficient permissions to access this page. If you think this is an error, please contact us at <a href="mailto: info@strideapp.co">info@strideapp.co</a>.</p>}
        />
    </Container>
);

export default AccountInactive;
