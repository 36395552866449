import React, { Component } from 'react';
import { Grid, Button, Form, Message } from "semantic-ui-react";
import OneOnOne from "../OneOnOne";
import { api } from "../../utils/API";
import moment from "moment-timezone";
import { toast } from 'react-semantic-toasts';


const defaultOneOnOneNotes = `Updates:

Working Items:

Action Items:`


class Report extends Component {

  constructor(props) {
    super(props);
    this.state = {
      report: {},
      oneOnOnes: [],
      oneOnOnesLoading: true,
    }
  }

  async componentDidMount() {
    const { reportId } = this.props;
    await this.fetchReport(reportId);
    await this.fetchOneOnOnes(reportId);
  }

  async fetchOneOnOnes(reportId) {
    const result = await api.get(`one_on_ones/?report_id=${reportId}`);
    this.setState({ oneOnOnes: result.data, oneOnOnesLoading: false });
  }

  async fetchReport(reportId) {
    const result = await api.get(`reports/${reportId}/`);
    this.setState({ report: result.data });
  }

  handleSaveEmployeeGoals = async (newText) => {
    const result = await api.patch(
      `reports/${this.state.report.id}/`,
      { employee_goals: newText }
    );
    this.setState({ report: result.data });
    toast({ title: "Successfully saved employee goals", type: "success", animation: "fade left" });
  };

  handleAddOneOnOne = async () => {
    const { oneOnOnes } = this.state;
    const result = await api.post(
      `one_on_ones/`,
      {
        report_id: this.state.report.id,
        week_of: moment(this.getCurrentWeekOf()).format("YYYY-MM-DD")
      }
    );
    this.setState({ oneOnOnes: [result.data, ...oneOnOnes] });
    toast({ title: "Successfully created 1-on-1", type: "success", animation: "fade left" });
  }

  handleDeleteOneOnOne = async (oneOnOneId) => {
    const { oneOnOnes } = this.state;

    await api.delete(`one_on_ones/${oneOnOneId}/`);
    this.setState({ oneOnOnes: oneOnOnes.filter(oneOnOne => oneOnOne.id !== oneOnOneId) });
    toast({ title: "Successfully deleted 1-on-1", type: "success", animation: "fade left" });
  }

  handleSaveOneOnOneNotes = async (oneOnOneId, newText) => {
    const { oneOnOnes } = this.state;
    try {
      const result = await api.patch(
        `one_on_ones/${oneOnOneId}/`,
        { notes: newText }
      );
      const newOneOnOne = result.data;

      this.setState({
        oneOnOnes: oneOnOnes.map(oneOnOne => oneOnOne.id === newOneOnOne.id ? newOneOnOne : oneOnOne)
      })

      toast({ title: "Successfully saved 1-on-1 notes", type: "success", animation: "fade left" });
    }
    catch (error) {
      toast({ title: "Could not save 1-on-1 notes. Please try again", type: "error", animation: "fade left" })
    }
  }

  getCurrentWeekOf = () => {
    return moment().startOf('week').add(1, 'days');
  }

  render() {
    const { report, oneOnOnes, oneOnOnesLoading } = this.state;

    const firstName = report.name ? report.name.split(' ')[0].trim() : "";

    return (
      <Grid stackable >
        <Grid.Column width={6}>
          <OneOnOne
            placeholder='Add employee goals...'
            key={report.employee_goals}
            deletable={false}
            title={firstName && `${firstName}'s Goals`}
            text={report.employee_goals}
            handleSave={(newText) => this.handleSaveEmployeeGoals(newText)}
          />
        </Grid.Column>
        <Grid.Column width={10}>
          <Form style={{ marginBottom: '1rem' }}>
            <Button primary fluid size='large' icon='plus circle' content={`Add 1-on-1 This Week`} onClick={this.handleAddOneOnOne} />
          </Form>
          {oneOnOnes.map(oneOnOne => (
            <OneOnOne
              key={`${oneOnOne.id}_${oneOnOne.notes}`}
              deletable={true}
              handleDelete={() => this.handleDeleteOneOnOne(oneOnOne.id)}
              title={`1-on-1 Week Of ${moment(oneOnOne.week_of).format('ll')}`}
              placeholder='Add 1-on-1 notes...'
              text={oneOnOne.notes || defaultOneOnOneNotes}
              handleSave={(newText) => this.handleSaveOneOnOneNotes(oneOnOne.id, newText)}
            />
          ))}
          {!oneOnOnesLoading && oneOnOnes.length === 0 && <Message color='blue' content="Click above to create your first 1-on-1." />}
        </Grid.Column>
      </Grid>
    )
  }
}


export default Report;
