import axios from "axios";

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: "json",
});

export const unauthApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: "json",
});