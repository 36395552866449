import React, { Component } from 'react';
import { Segment, Header, Form, Confirm } from "semantic-ui-react";
import { api } from "../../utils/API";
import 'react-day-picker/lib/style.css';
import { toast } from 'react-semantic-toasts';


class ReportSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            oneOnOneFrequency: props.directReport.settings.one_on_one_frequency,
            selectedMonday: props.directReport.settings.week_of_next_one_on_one,
            openConfirm: false,
        }
    }

    handleDelete = async () => {
        const { directReport, fetchReportSettings } = this.props;
        await api.delete(`reports/${directReport.id}/`);
        fetchReportSettings();
        toast({ title: "Successfully deleted direct report", type: "success", animation: "fade left" });
    }

    render() {
        const { openConfirm } = this.state;
        const { directReport } = this.props;

        return (
            <Segment>
                <Header size='medium'>{directReport.name}</Header>
                <Form>
                
                    <Form.Group>
                        <Form.Button negative content="Delete Report" onClick={() => this.setState({ openConfirm: true })} />
                    </Form.Group>

                    <Confirm
                        content='Are you sure you want to delete this direct report?'
                        open={openConfirm}
                        onCancel={() => this.setState({ openConfirm: false })}
                        onConfirm={this.handleDelete}
                    />
                </Form>
            </Segment>
        )
    }
}


export default ReportSettings;