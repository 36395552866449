import React, { Component } from 'react';
import { Container, Segment, Header, Form, Message, Loader } from "semantic-ui-react";
import { api } from "../../utils/API";
import { mixpanel } from "../../utils/tracking";
import { toast } from 'react-semantic-toasts';

import ReportSettings from "../ReportSettings";
import AccountInactive from '../AccountInactive';


class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            directReports: [],
            name: "",
            loading: true,
            forbidden: false,
        }
    }

    async componentDidMount() {
        mixpanel.track("Viewed Manage Reports Page");
        await this.fetchReportSettings();
    }

    async fetchReportSettings() {
        let result;
        try {
            result = await api.get('reports/');
            this.setState({
                directReports: result.data,
                loading: false
            });
        }
        catch (error) {
            if (error.response && error.response.status === 403) {
                this.setState({ forbidden: true });
            }
            else {
                throw error;
            }
        }
    }

    handleNameChange = (e, { n, value }) => this.setState({ name: value })

    handleAddNewReport = async () => {
        const { name } = this.state;
        await api.post(
            `reports/`,
            { name }
        );
        this.setState({ name: '' })
        toast({ title: "Successfully added new direct report", type: "success", animation: "fade left" });
        await this.fetchReportSettings()
    }

    render() {
        const { directReports, name, loading, forbidden } = this.state;

        if (forbidden) {
            return <AccountInactive />
        }

        if (loading) {
            return (
                <Loader active />
            )
        }

        return (
            <Container style={{ margin: '4em' }}>
                {directReports.length === 0 && (
                    <Message
                        color='blue'
                        header='No Direct Reports'
                        content='Add your direct reports here to begin using Stride.'
                    />
                )}

                <Container text style={{ margin: '4em' }}>

                    {directReports.map(directReport => {
                        return (
                            <ReportSettings
                                key={directReport.id}
                                oneOnOneFrequency={directReport.settings.one_on_one_frequency}
                                directReport={directReport}
                                fetchReportSettings={() => this.fetchReportSettings()}
                            />
                        )
                    })}
                    <Segment>
                        <Header size='medium'>Add Direct Report</Header>
                        <Form onSubmit={this.handleAddNewReport}>
                            <Form.Group>
                                <Form.Input
                                    placeholder='Name'
                                    name='name'
                                    value={name}
                                    onChange={this.handleNameChange}
                                />
                                <Form.Button primary content='Add' icon='add user' />
                            </Form.Group>
                        </Form>
                    </Segment>
                </Container>
            </Container>
        )
    }
}


export default Settings;
