import React, { Component } from 'react';
import { Grid, Button, Form, Message } from "semantic-ui-react";
import OneOnOne from "../OneOnOne";
import { api } from "../../utils/API";
import moment from "moment-timezone";
import { toast } from 'react-semantic-toasts';


const defaultLeaderNotes = ''


class MyNotes extends Component {

  constructor(props) {
    super(props);
    this.state = {
      leader: {},
      leaderNotes: [],
    }
  }

  async componentDidMount() {
    await this.fetchLeader();
    await this.fetchLeaderNotes();
  }

  async fetchLeaderNotes() {
    const result = await api.get(`leader_notes/`);
    this.setState({ leaderNotes: result.data });
  }

  async fetchLeader() {
    const result = await api.get(`leaders/self/`);
    this.setState({ leader: result.data });
  }

  handleSaveGoals = async (newText) => {
    const result = await api.patch(
      `leaders/self/`,
      { goals: newText }
    );
    this.setState({ leader: result.data });
    toast({ title: "Successfully saved goals", type: "success", animation: "fade left" });
  };

  handleAddLeaderNote = async () => {
    const { leaderNotes } = this.state;
    const result = await api.post(
      `leader_notes/`,
      {
        week_of: moment(this.getCurrentWeekOf()).format("YYYY-MM-DD")
      }
    );
    this.setState({ leaderNotes: [result.data, ...leaderNotes] });
    toast({ title: "Successfully created new note", type: "success", animation: "fade left" });
  }

  handleDeleteLeaderNote = async (leaderNoteId) => {
    const { leaderNotes } = this.state;

    await api.delete(`leader_notes/${leaderNoteId}/`);
    this.setState({ leaderNotes: leaderNotes.filter(leaderNote => leaderNote.id !== leaderNoteId) });
    toast({ title: "Successfully deleted note", type: "success", animation: "fade left" });
  }

  handleSaveLeaderNote = async (leaderNoteId, newText) => {
    const { leaderNotes } = this.state;
    try {
      const result = await api.patch(
        `leader_notes/${leaderNoteId}/`,
        { notes: newText }
      );
      const newLeaderNote = result.data;

      this.setState({
        leaderNotes: leaderNotes.map(leaderNote => leaderNote.id === newLeaderNote.id ? newLeaderNote : leaderNote)
      })

      toast({ title: "Successfully saved note", type: "success", animation: "fade left" });
    }
    catch (error) {
      toast({ title: "Could not save note. Please try again", type: "error", animation: "fade left" })
    }
  }

  getCurrentWeekOf = () => {
    return moment().startOf('week').add(1, 'days');
  }

  render() {
    const { leaderNotes, leader } = this.state;

    return (
      <Grid stackable >
        <Grid.Column width={6}>
          <OneOnOne
            placeholder='Add goals...'
            key={`leaderGoals_${leader.goals}`}
            deletable={false}
            title='My Goals'
            text={leader.goals}
            handleSave={(newText) => this.handleSaveGoals(newText)}
          />
        </Grid.Column>
        <Grid.Column width={10}>
          <Form style={{ marginBottom: '1rem' }}>
            <Button primary fluid size='large' icon='plus circle' content={`Add Notes This Week`} onClick={this.handleAddLeaderNote} />
          </Form>
          {leaderNotes.map(leaderNote => (
            <OneOnOne
              deleteConfirmText='Are you sure you want to delete this note?'
              key={`${leaderNote.id}_${leaderNote.notes}`}
              deletable={true}
              handleDelete={() => this.handleDeleteLeaderNote(leaderNote.id)}
              title={`My Notes Week Of ${moment(leaderNote.week_of).format('ll')}`}
              placeholder='Add notes...'
              text={leaderNote.notes || defaultLeaderNotes}
              handleSave={(newText) => this.handleSaveLeaderNote(leaderNote.id, newText)}
            />
          ))}
          {leaderNotes.length === 0 && <Message color='blue' content="Click above to create your first weekly notes." />}
        </Grid.Column>
      </Grid>
    )
  }
}


export default MyNotes;
