import React, { Component } from 'react';
import { Dropdown, Container, Header } from "semantic-ui-react";
import { XAxis, YAxis, ResponsiveContainer, Legend, BarChart, Bar, CartesianGrid, LabelList, Label } from 'recharts';

import { api } from "../../utils/API";
import { mixpanel } from "../../utils/tracking";


const toPercent = (decimal, fixed = 0) => {
    const result = `${(decimal * 100).toFixed(fixed)}%`;
    return result;
}


const colors = [
    "#F1C233",
    "#3362F1",
    "#2BCBBA",
    "#A55EEA",
    "#FC5C65",
    "#4B6584",
    "#eb9e34",
    "#eb34d6"
];

const months_back_options = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24].map((num) => (
    {
        key: num,
        text: num,
        value: num
    }
));

class ReportingInternal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            companyAnalytics: {},
            loading: true,
            num_months_back: 6,
            companyList: []
        }
    }

    async componentDidMount() {
        mixpanel.track("Viewed Internal Reporting Page");
        await this.fetchCompanyList();
    }

    async fetchCompanyList() {
        let result = await api.get(`company`);
        this.setState({
            companyList: result.data,
        });
    }

    async onChangeCompany(event, data) {
        const { num_months_back } = this.state;
        const companyId = data.value

        const result = await api.get(`company/${companyId}/internal_analytics?num_months_back=${num_months_back}`);
        this.setState({
            companyAnalytics: result.data,
            companyId,
            loading: false
        });
    }

    async onChangeNumMonthsBack(event, data) {
        const { companyId } = this.state;
        const num_months_back = data.value

        const result = await api.get(`company/${companyId}/internal_analytics?num_months_back=${num_months_back}`);
        this.setState({
            companyAnalytics: result.data,
            num_months_back,
            loading: false
        });
    }

    render() {
        const { companyAnalytics, loading, companyList } = this.state;

        const companyOptions = companyList.map(company => (
            {
                key: company.id,
                text: company.name,
                value: company.id
            }
        )
        )

        if (loading) {
            return (
                <Container style={{ margin: '4em' }}>

                    <Header as='h1' textAlign="center">
                        Internal Reporting
                    </Header>

                    <br />

                    <Dropdown placeholder='Select Company' search selection options={companyOptions} onChange={(event, data) => this.onChangeCompany(event, data)} />
                </Container>
            )
        }

        return (
            <Container style={{ margin: '4em' }}>

                <Header as='h1' textAlign="center">
                    Internal Reporting
                </Header>

                <br />

                <Dropdown placeholder='Select Company' search selection options={companyOptions} onChange={(event, data) => this.onChangeCompany(event, data)} />
                <br />
                <br />
                <span>Number of months back: </span><Dropdown placeholder='Number of Months Back' selection options={months_back_options} defaultValue={6} onChange={(event, data) => this.onChangeNumMonthsBack(event, data)} />
                <br />
                <br />
                <br />

                <React.Fragment>
                    <h1>Completed Sessions</h1>
                    <ResponsiveContainer width="100%" height={400}>
                        <BarChart
                            width={500}
                            height={500}
                            data={companyAnalytics.completed_sessions_new_returning}
                            margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: 5
                            }}
                        >
                            <CartesianGrid vertical={false} stroke="#C2D0FB80" />
                            <XAxis dataKey="month_str" stroke="#3362F1" strokeWidth={3} />
                            <YAxis
                                stroke="#3362F1"
                                strokeWidth={3}
                                fontFamily="Poppins"
                                fontStyle="normal"
                                fontWeight={600}
                            />
                            <Legend
                                layout="vertical"
                                align="center"
                                verticalAlign="bottom"
                                fontFamily="Poppins"
                                fontStyle="normal"
                                fontWeight={600}
                            />
                            <Bar
                                dataKey="events_previous"
                                stackId="a"
                                fill="#3362F1"
                                name="Previously Completed Sessions"
                            >
                                <LabelList
                                    dataKey="events_previous"
                                    position="center"
                                    fill="white"
                                    fontFamily="Poppins"
                                    fontStyle="normal"
                                    fontWeight={600}
                                />
                            </Bar>
                            <Bar
                                dataKey="events_new"
                                stackId="a"
                                fill="#F1C233"
                                name="New Sessions"
                            >
                                <LabelList
                                    dataKey="events_new"
                                    position="center"
                                    // fill="white"
                                    fontFamily="Poppins"
                                    fontStyle="normal"
                                    fontWeight={600}
                                />
                                <LabelList
                                    dataKey="events_total"
                                    position="top"
                                    offset="20"
                                    fontFamily="Poppins"
                                    fontStyle="normal"
                                    fontWeight={600}
                                />
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>

                    <h1>Utilization By Role</h1>
                    <ResponsiveContainer width="100%" height={400}>
                        <BarChart
                            width={500}
                            height={500}
                            data={companyAnalytics.utilization_by_role}
                            margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: 5
                            }}
                            stackOffset="expand"
                        >
                            <CartesianGrid vertical={false} stroke="#C2D0FB80" />
                            <XAxis dataKey="month_str" stroke="#3362F1" strokeWidth={3} />
                            <YAxis
                                tickFormatter={(value, index) => toPercent(value, 0)}
                                stroke="#3362F1"
                                strokeWidth={3}
                                fontFamily="Poppins"
                                fontStyle="normal"
                                fontWeight={600}
                            />
                            <Legend
                                layout="vertical"
                                align="center"
                                verticalAlign="bottom"
                                fontFamily="Poppins"
                                fontStyle="normal"
                                fontWeight={600}
                            />
                            <Bar
                                dataKey="ic"
                                stackId="a"
                                fill="#3362F1"
                                name="Individual Contributor"
                            >
                                <LabelList
                                    dataKey="ic_percent"
                                    position="center"
                                    fill="white"
                                    fontFamily="Poppins"
                                    fontStyle="normal"
                                    fontWeight={600}
                                />
                            </Bar>
                            <Bar dataKey="manager" stackId="a" fill="#F1C233" name="Manager">
                                <LabelList
                                    dataKey="manager_percent"
                                    position="center"
                                    fill="white"
                                    fontFamily="Poppins"
                                    fontStyle="normal"
                                    fontWeight={600}
                                />
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>

                    <h1>Utilization By Department</h1>

                    <p>
                        The data below represents the departments of employees who completed at
                        least 1 session during each reporting period.
                    </p>
                    <p>
                        <span style={{ color: "red" }}>
                            Please note departments with less than 5 employees are categorized as "Other".
                        </span>
                    </p>
                    <ResponsiveContainer width="100%" height={400}>
                        <BarChart
                            width={500}
                            height={500}
                            data={companyAnalytics.utilization_by_dept}
                            margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: 5
                            }}
                            stackOffset="expand"
                        >
                            <CartesianGrid vertical={false} stroke="#C2D0FB80" />
                            <XAxis dataKey="month_str" stroke="#3362F1" strokeWidth={3} />
                            <YAxis
                                tickFormatter={(value, index) => toPercent(value, 0)}
                                stroke="#3362F1"
                                strokeWidth={3}
                                fontFamily="Poppins"
                                fontStyle="normal"
                                fontWeight={600}
                            />
                            <Legend
                                layout="horizontal"
                                align="center"
                                verticalAlign="bottom"
                                fontFamily="Poppins"
                                fontStyle="normal"
                                fontWeight={600}
                            />
                            {companyAnalytics.departments.map((dept, index) => {
                                return (
                                    <Bar key={index} dataKey={dept} stackId="a" fill={colors[index]}>
                                        <LabelList
                                            dataKey={`${dept} percent`}
                                            position="center"
                                            fill="white"
                                            fontFamily="Poppins"
                                            fontStyle="normal"
                                            fontWeight={600}
                                        />
                                    </Bar>
                                );
                            })}
                        </BarChart>
                    </ResponsiveContainer>
                </React.Fragment>
            </Container>
        )
    }
}


export default ReportingInternal;
