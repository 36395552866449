import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import './index.css';
import App from './App';
import 'semantic-ui-less/semantic.less';
import * as serviceWorker from './serviceWorker';

Sentry.init({ dsn: "https://bcf3ec93e70b4f36995e7a36d2f2ba22@sentry.io/2643548", environment: process.env.REACT_APP_ENV });
ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
