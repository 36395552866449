import React, { Component } from 'react';
import { Form, Button, Header, Dropdown, Confirm, Segment, Icon } from "semantic-ui-react";
import TextareaAutosize from 'react-textarea-autosize';

class OneOnOne extends Component {

  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      newNotes: props.text,
      mouseOver: false,
      openConfirm: false,
    }
  }

  handleChangeText = (event) => {
    this.setState({ newNotes: event.target.value });
  };

  handleSave = () => {
    const { newNotes } = this.state;
    const { handleSave } = this.props;
    this.setState({ editing: false, mouseOver: false, })
    handleSave(newNotes);
  }

  handleCancel = () => {
    this.setState({ editing: false, newNotes: this.props.text, mouseOver: false, });
  }

  handleClickDelete = async () => {
    this.setState({ openConfirm: true });
  }

  moveCaretAtEnd(e) {
    var temp_value = e.target.value
    e.target.value = ''
    e.target.value = temp_value
  }

  renderBody = () => {
    const { newNotes, editing, mouseOver } = this.state;
    const { placeholder, deletable } = this.props;

    if (editing) {
      return (
        <Form>
          <Form.Field style={{ marginBottom: '6px' }}>
            <TextareaAutosize
              style={{ padding: 0 }}
              value={newNotes}
              minRows={1}
              onChange={this.handleChangeText}
              placeholder={placeholder}
              autoFocus
              onFocus={this.moveCaretAtEnd}
            />
          </Form.Field>
          <Form.Field>
            <Button primary content="Save" onClick={this.handleSave} size="small" compact />
            <Button content="Cancel" onClick={this.handleCancel} size="small" compact />
            {deletable && <Button negative content="Delete" onClick={this.handleClickDelete} size="small" compact />}
          </Form.Field>
        </Form>
      );
    }
    return (
      <div
        style={{ 'whiteSpace': 'pre-line', background: mouseOver ? '#E6ECF0' : 'transparent' }}
        onMouseOver={() => this.setState({ mouseOver: true })}
        onMouseOut={() => this.setState({ mouseOver: false })}
        onClick={() => this.setState({ editing: true })}
      >
        {newNotes ?
          <span>{newNotes}</span> :
          <span style={{ color: 'rgb(107, 119, 140)' }}>{placeholder}</span>
        }
      </div>
    );
  }

  render() {
    const { openConfirm } = this.state;
    const { title, handleDelete, deletable, deleteConfirmText } = this.props;

    return (
      <div style={{ marginBottom: '1rem' }}>
        <Header attached="top" as='h3' style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span>{title}</span>
          <Dropdown direction='left' icon="ellipsis vertical" style={{ color: 'grey' }} >
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => this.setState({ editing: true })}>
                <Icon name='edit' />
                Edit
              </Dropdown.Item>
              {deletable &&
                <Dropdown.Item onClick={this.handleClickDelete}>
                  <Icon name='delete' />
                  Delete
                </Dropdown.Item>
              }
            </Dropdown.Menu>
          </Dropdown>
        </Header>

        <Segment attached='bottom'>
          {this.renderBody()}
        </Segment>

        <Confirm
          content={deleteConfirmText || 'Are you sure you want to delete this 1-on-1?'}
          open={openConfirm}
          onCancel={() => this.setState({ openConfirm: false })}
          onConfirm={handleDelete}
        />
      </div>
    )
  }
}

export default OneOnOne;