import React, { Component } from 'react';
import { Container, Tab, Loader, Responsive } from "semantic-ui-react";
import { api } from "../../utils/API";
import { REPORTS_TAB_RESPONSIVE_MIN_WIDTH, REPORTS_TAB_RESPONSIVE_MAX_WIDTH } from "../../utils/constants";
import {
  Redirect
} from "react-router-dom";
import Report from "../Report";
import AccountInactive from '../AccountInactive';
import MyNotes from 'components/MyNotes';
import { mixpanel } from "../../utils/tracking";

class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      directReports: [],
      loading: true,
      forbidden: false,
    }
  }

  async componentDidMount() {
    mixpanel.track("Viewed Dashboard Page");
    await this.fetchReports();
  }

  async fetchReports() {
    let reports;
    try {
      reports = await api.get('reports/');
      this.setState({ directReports: reports.data, loading: false });
    }
    catch (error) {
      if (error.response && error.response.status === 403) {
        this.setState({ forbidden: true });
      }
      else {
        throw error;
      }
    }
  }

  render() {
    const { hideManageReportsOnDashboard } = this.props;
    const { directReports, loading, forbidden } = this.state;

    if (forbidden) {
      return <AccountInactive />
    }

    if (loading) {
      return (
        <Loader active />
      )
    }

    if (!hideManageReportsOnDashboard && directReports.length === 0) {
      return <Redirect to="/settings" />;
    }

    if (!hideManageReportsOnDashboard) {
      const panes = directReports.map(report => {
        return (
          {
            menuItem: report.name,
            render: () => (
              <Tab.Pane key={report.id} as="div">
                <Report reportId={report.id} />
              </Tab.Pane>
            )
          })
      });

      panes.unshift({
        menuItem: 'My Notes',
        render: () => {
          return (
            <Tab.Pane key={"myNotes"} as="div">
              <MyNotes />
            </Tab.Pane>
          );
        }
      });

      return (
        <Container style={{ margin: '4em' }}>
          <div style={{ marginBottom: '2em' }}>
            <Responsive maxWidth={REPORTS_TAB_RESPONSIVE_MIN_WIDTH}>
              <Tab grid={{ paneWidth: 10, tabWidth: 6 }} menu={{ vertical: true, tabular: true, fluid: true }} panes={panes} />
            </Responsive>
            <Responsive minWidth={REPORTS_TAB_RESPONSIVE_MAX_WIDTH}>
              <Tab grid={{ paneWidth: 12, tabWidth: 4 }} menu={{ vertical: true, tabular: true, fluid: true }} panes={panes} />
            </Responsive>
          </div>
        </Container>
      )
    }

    return (
      <Container style={{ margin: '4em' }}>
        <MyNotes />
      </Container>
    )

  }
}


export default Reports;