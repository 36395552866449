import React, { Component } from 'react';
import { Container, Header, Loader, Statistic } from "semantic-ui-react";
import { XAxis, YAxis, Tooltip, ResponsiveContainer, Legend, BarChart, Bar, CartesianGrid } from 'recharts';
import moment from "moment-timezone";
import AccountInactive from '../AccountInactive';

import { api } from "../../utils/API";
import { mixpanel } from "../../utils/tracking";


class StatisticLabelChange extends Component {
    render() {
        const { changeAmount, isPercent } = this.props;

        return (<Statistic.Label style={{ color: changeAmount >= 0 ? 'green' : 'red', fontSize: 12 }}>
            {changeAmount >= 0 ? '+' : ''}{changeAmount}{isPercent ? '%' : ''} from previous week
        </Statistic.Label>);
    }
}

class Reporting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            companyAnalytics: {},
            loading: true,
            forbidden: false,
        }
    }

    async componentDidMount() {
        mixpanel.track("Viewed Reporting Page");
        await this.fetchAnalytics();
    }

    async fetchAnalytics() {
        const { companyId } = this.props;

        // allow overriding the company with a query param
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const reportingCompanyId = params.get('reporting_company_id');

        const fetchCompanyId = reportingCompanyId ? reportingCompanyId : companyId;

        let result;
        try {
            result = await api.get(`company/${fetchCompanyId}/analytics`);
            this.setState({
                companyAnalytics: result.data,
                loading: false
            });
        }
        catch (error) {
            if (error.response && error.response.status === 403) {
                this.setState({ forbidden: true });
            }
            else {
                throw error;
            }
        }
    }

    render() {
        const { companyAnalytics, loading, forbidden } = this.state;

        if (forbidden) {
            return <AccountInactive />
        }

        if (loading) {
            return (
                <Loader active />
            )
        }

        const active_per_department = companyAnalytics.active_per_department.map(entry => {
            if (entry.department === "Other") {
                return {
                    ...entry,
                    department: "*Other"
                }
            }
            return entry;
        })

        const engaged_with_stride = (100 * companyAnalytics.lifetime_engaged / companyAnalytics.total_users).toFixed(0)
        const engaged_with_stride_prev = (100 * companyAnalytics.prev_week.lifetime_engaged / companyAnalytics.prev_week.total_users).toFixed(0)

        return (
            <Container style={{ margin: '4em' }}>

                <Header as='h1' textAlign="center">
                    Reporting
                    <Header.Subheader>
                        As of {moment(companyAnalytics.report_date).format("MMMM D, YYYY")}<br />(updated every Monday)
                    </Header.Subheader>
                </Header>

                <br />
                <br />
                <br />
                <br />

                <Statistic.Group
                    widths='five'
                    size='small'
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        justifyContents: "space-around"
                    }}>
                    <Statistic style={{ margin: 'auto' }}>
                        <Statistic.Value>{engaged_with_stride}%</Statistic.Value>
                        <StatisticLabelChange
                            changeAmount={engaged_with_stride - engaged_with_stride_prev}
                            isPercent={true}>
                        </StatisticLabelChange>
                        <Statistic.Label>{companyAnalytics.lifetime_engaged} of {companyAnalytics.total_users} total onboarded<br />engaged with Stride</Statistic.Label>
                    </Statistic>

                    <Statistic style={{ margin: 'auto' }}>
                        <Statistic.Value>{companyAnalytics.lifetime_engagements}</Statistic.Value>
                        <StatisticLabelChange
                            changeAmount={companyAnalytics.lifetime_engagements - companyAnalytics.prev_week.lifetime_engagements}
                            isPercent={false}>
                        </StatisticLabelChange>
                        <Statistic.Label>Total Engagements</Statistic.Label>
                    </Statistic>

                    <Statistic style={{ margin: 'auto' }}>
                        <Statistic.Value>{(companyAnalytics.lifetime_engagements / companyAnalytics.lifetime_engaged).toFixed(1)}</Statistic.Value>
                        <StatisticLabelChange
                            changeAmount={((companyAnalytics.lifetime_engagements / companyAnalytics.lifetime_engaged) - (companyAnalytics.prev_week.lifetime_engagements / companyAnalytics.prev_week.lifetime_engaged)).toFixed(1)}
                            isPercent={false}>
                        </StatisticLabelChange>
                        <Statistic.Label>Average Engagements<br />Per Engaged User</Statistic.Label>
                    </Statistic>

                    <Statistic style={{ margin: 'auto' }}>
                        <Statistic.Value>{companyAnalytics.lifetime_coaching_sessions}</Statistic.Value>
                        <StatisticLabelChange
                            changeAmount={companyAnalytics.lifetime_coaching_sessions - companyAnalytics.prev_week.lifetime_coaching_sessions}
                            isPercent={false}>
                        </StatisticLabelChange>
                        <Statistic.Label>Total Coaching<br />Sessions</Statistic.Label>
                    </Statistic>

                    <Statistic style={{ margin: 'auto' }}>
                        <Statistic.Value>{(100 * companyAnalytics.lifetime_coached_twice / companyAnalytics.lifetime_coached).toFixed(0)}%</Statistic.Value>
                        <StatisticLabelChange
                            changeAmount={((100 * companyAnalytics.lifetime_coached_twice / companyAnalytics.lifetime_coached) - (100 * companyAnalytics.prev_week.lifetime_coached_twice / companyAnalytics.prev_week.lifetime_coached)).toFixed(0)}
                            isPercent={true}>
                        </StatisticLabelChange>
                        <Statistic.Label>Came Back For a 2nd<br />Coaching Session</Statistic.Label>
                    </Statistic>
                </Statistic.Group>

                <br />
                <br />

                <br />
                <br />

                <Header as='h3'>
                    Engaged Users By Week
                </Header>
                <br />
                <ResponsiveContainer height={400}>
                    <BarChart data={companyAnalytics.new_returning_users_per_week}>
                        <XAxis dataKey="start_of_week" />
                        <YAxis />
                        <CartesianGrid vertical={false} strokeDasharray="3 3" />
                        <Tooltip />
                        <Legend verticalAlign="top" />
                        <Bar
                            type="monotone"
                            dataKey="active_new"
                            name="New"
                            stackId="a"
                            fill="#3362f1"
                        />
                        <Bar
                            type="monotone"
                            dataKey="active_repeat"
                            name="Repeat"
                            stackId="a"
                            fill="#f1c233"
                        />
                    </BarChart>
                </ResponsiveContainer>

                <br />
                <br />

                <Header as='h3'>
                    Engaged Users By Department
                </Header>
                <br />
                <ResponsiveContainer height={400}>
                    <BarChart data={active_per_department}>
                        <XAxis dataKey="department" />
                        <YAxis />
                        <CartesianGrid vertical={false} strokeDasharray="3 3" />
                        <Tooltip />
                        <Legend verticalAlign="top" />

                        <Bar
                            type="monotone"
                            dataKey="active_manager"
                            name="Managers"
                            stackId="a"
                            fill="#3362f1"
                        />
                        <Bar
                            type="monotone"
                            dataKey="active_individual_contributor"
                            name="Individual Contributors"
                            stackId="a"
                            fill="#f1c233"
                        />
                    </BarChart>
                </ResponsiveContainer>
                <p>*Note: Departments with only 1 or 2 managers or individual contributors are categorized as "Other" to prevent identification</p>

                <br />
                <br />

                <Header as="h3">Definitions</Header>
                <p>A <em>coaching session</em> is the exchange of at least one message with a Stride coach on a day.</p>
                <p>An <em>engagement</em> is a coaching session or an interaction with a Stride automated message (link or button click) on a day.</p>
                <br />
                <br />
            </Container>
        )
    }
}


export default Reporting;
