import React, { Component } from 'react';
import { unauthApi, api } from "./utils/API";
import { mixpanel } from "./utils/tracking";
import Reports from "./components/Reports";
import LoggingIn from "./components/LoggingIn";
import Settings from "./components/Settings";
import Resources from "./components/Resources";
import Reporting from "./components/Reporting";
import ReportingInternal from "./components/ReportingInternal";
import { SemanticToastContainer } from 'react-semantic-toasts';
import 'react-semantic-toasts/styles/react-semantic-alert.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import MenuBar from './components/MenuBar';

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loggedIn: false,
      logoutDisabled: false,
      hideManageReportsOnDashboard: true,
      isCompanyAdmin: false,
      isStrideAdmin: false,
    }
  }

  async fetchGlobalSettings() {
    const result = await api.get(`leaders/self/`);
    mixpanel.identify(result.data.id);
    this.setState({
      hideManageReportsOnDashboard: result.data.hide_manage_reports_on_dashboard,
      isCompanyAdmin: result.data.is_company_admin,
      isStrideAdmin: result.data.is_stride_admin,
      companyId: result.data.company_id,
      loggedIn: true,
    });
  }

  async componentDidMount() {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const code = params.get('code');

    if (code) {
      let result;
      try {
        result = await unauthApi.post('login/slack', { code });
      }
      catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.clear();
          window.location.replace(process.env.REACT_APP_URL);
        }
        else {
          throw error;
        }
      }

      localStorage.setItem('token', result.data.token);
      api.defaults.headers.common["Authorization"] = 'Token ' + localStorage.getItem('token');

      window.location.replace(process.env.REACT_APP_URL);
    }
    else if (localStorage.getItem('token')) {
      api.defaults.headers.common["Authorization"] = 'Token ' + localStorage.getItem('token');
      await this.fetchGlobalSettings();
    }
    else {
      // delay so that the navigation bar has time to load (more visually appealing)
      setTimeout(() => {
        const uriEncodedUrl = encodeURIComponent(process.env.REACT_APP_URL);
        // this is the login with slack flow - add to slack flow starts with a different link
        window.location = `https://slack.com/oauth/v2/authorize?user_scope=identity.basic,identity.email,identity.team&client_id=${process.env.REACT_APP_SLACK_CLIENT_ID}&redirect_uri=${uriEncodedUrl}`;
      }, 100);

    }
  }

  onClickLogOut = () => {
    mixpanel.track("Click Logout");
    this.setState({ logoutDisabled: true })
    localStorage.clear();
    api.defaults.headers.common["Authorization"] = null;
    window.location = process.env.REACT_APP_MARKETING_URL;
  }

  renderBody = () => {
    const { loggedIn, hideManageReportsOnDashboard, companyId } = this.state;

    if (loggedIn) {

      return (
        <Switch>
          <Route exact path="/">
            <Redirect to="/reporting" />
            {/* <Reports
              hideManageReportsOnDashboard={hideManageReportsOnDashboard}
            /> */}
          </Route>
          {/* <Route path="/settings">
            <Settings />
          </Route> */}
          {/* <Route path="/resources">
            <Resources />
          </Route> */}
          <Route path="/reporting">
            <Reporting companyId={companyId} />
          </Route>
          <Route path="/reporting_internal">
            <ReportingInternal companyId={companyId} />
          </Route>
        </Switch>
      )
    }

    return <LoggingIn></LoggingIn>
  }

  render() {
    const {
      logoutDisabled,
      hideManageReportsOnDashboard,
      isCompanyAdmin,
      isStrideAdmin
    } = this.state;

    return (
      <Router>
        <MenuBar
          isCompanyAdmin={isCompanyAdmin}
          isStrideAdmin={isStrideAdmin}
          hideManageReportsOnDashboard={hideManageReportsOnDashboard}
          logoutDisabled={logoutDisabled}
          onClickLogOut={this.onClickLogOut} >
          <SemanticToastContainer />
          {this.renderBody()}
        </MenuBar>
      </Router >
    );
  }
}

export default App;
